<!--  -->
<template>
  <div class=""></div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      id: 0,
      is_buy: 0,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    console.log(this.$route.query, "1111111111111");
    this.id = this.$route.query.id;
    this.$handleRoute({ id: this.id  },'QuestionInfo')
  },
  //方法集合
  methods: {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style  lang='less' scoped>
</style>